<template>
  <div>
    <h3>Taxas e limites</h3>
    <validation-observer ref="taxForm" #default="{ invalid }">
      <b-row>
        <b-col cols="3">
          <b-form-group label="Taxa de Escrituração" label-for="taxa-escrituracao">
            <validation-provider
              #default="{ errors }"
              name="Taxa de Escrituração"
              rules="required"
              vid="taxa-escrituracao"
            >
              <money
                id="taxa-escrituracao"
                v-model="company.taxa_escrituracao"
                class="form-control"
                :class="{ 'is-invalid': errors.length > 0 }"
                v-bind="percentage"
                :maxlength="8"
                name="taxa-escrituracao"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Taxas a.m" label-for="tax">
            <validation-provider #default="{ errors }" name="Taxas a.m" rules="required" vid="tax">
              <money
                id="tax"
                v-model="company.taxa_juros"
                class="form-control"
                :class="{ 'is-invalid': errors.length > 0 }"
                v-bind="percentage"
                :maxlength="8"
                name="tax"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Limite" label-for="limit">
            <validation-provider
              #default="{ errors }"
              name="Limite"
              rules="required|min_value:10000|multipleOf1000"
              vid="limit"
            >
              <money
                id="limit"
                v-model="company.limite"
                class="form-control"
                :class="{ 'is-invalid': errors.length > 0 }"
                v-bind="money"
                name="limit"
                :maxlength="13"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Mora (juros por atraso)" label-for="tax">
            <validation-provider #default="{ errors }" name="Mora" rules="required" vid="mora">
              <money
                id="mora"
                v-model="company.mora_atraso"
                class="form-control"
                :class="{ 'is-invalid': errors.length > 0 }"
                v-bind="percentage"
                :maxlength="8"
                name="mora"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Multa por atraso" label-for="tax">
            <validation-provider #default="{ errors }" name="Multa" rules="required" vid="multa">
              <money
                id="multa"
                v-model="company.multa_atraso"
                class="form-control"
                :class="{ 'is-invalid': errors.length > 0 }"
                v-bind="percentage"
                :maxlength="8"
                name="multa"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Limite por dia" label-for="tax">
            <validation-provider #default="{ errors }" name="Limite por dia" rules="required" vid="limite_por_dia">
              <money
                id="limite_por_dia"
                v-model="company.limite_por_dia"
                class="form-control"
                :class="{ 'is-invalid': errors.length > 0 }"
                v-bind="money"
                :maxlength="13"
                name="limite_por_dia"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Limite aprovado" label-for="limitIsApproved">
            <validation-provider
              #default="{ errors }"
              name="Limite aprovado"
              rules="required"
              vid="limitIsApproved"
            >
              <b-form-checkbox
                id="limitIsApproved"
                v-model="company.limite_aprovado"
                :state="errors.length > 0 ? false : null"
                name="limite_aprovado"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row
        v-if="analysisIsCompleted"
        class="d-flex flex-row justify-content-start align-items-center"
      >
        <b-col cols="4">
          <b-form-group label="Rating da empresa" label-for="rating">
            <b-form-input
              id="rating"
              v-model="company.rating"
              name="rating"
              readonly
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col v-if="company.analise_xlsx_link" cols="3">
          <div class="d-flex justify-content-start align-items-end">
            <b-badge
              class="cursor-pointer mt-1 mr-1 w-fit"
              variant="light-primary"
              @click="downloadURI(company.analise_xlsx_link)"
            >
              Relatório Excel
            </b-badge>
            <feather-icon
              class="cursor-pointer"
              icon="DownloadIcon"
              size="20"
              stroke="#093272"
              @click="downloadURI(company.analise_xlsx_link)"
            />
          </div>
        </b-col>
        <b-col v-if="company.analise_pdf_link" cols="3">
          <div class="d-flex justify-content-start align-items-end">
            <b-badge
              class="cursor-pointer mt-1 mr-1 w-fit"
              variant="light-primary"
              @click="downloadURI(company.analise_pdf_link)"
            >
              Relatório PDF
            </b-badge>
            <feather-icon
              class="cursor-pointer"
              icon="DownloadIcon"
              size="20"
              stroke="#093272"
              @click="downloadURI(company.analise_pdf_link)"
            />
          </div>
        </b-col>
        <b-col cols="2" />
      </b-row>

      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-center mb-1">
            <h4 class="mb-0">Documentação do cliente</h4>
            <span class="mb-0">
              Data do envio: {{ formatDate(company.envio_documentacao_date) }}
            </span>
            <input type="file" ref="inputFile" style="display: none" @change="handleFileUpload" />
          </div>
        </b-col>
        <b-col cols="12">
          <div class="admin-docs rounded-lg w-100 d-flex flex-row table-responsive h-100">
            <div
              class="admin-docs w-100 p-2 d-flex flex-column justify-content-between"
              v-for="(tipo, index) in documentTypes"
              :key="index"
            >
              <div>
                <h4 class="font-weight-bold mb-1">{{ tipo.title }}</h4>
                <div class="docs">
                  <div class="d-flex mb-5" v-if="!docs[tipo.key]">
                    <feather-icon icon="AlertCircleIcon" size="22" class="text-warning mr-1" />
                    <span>Não enviado</span>
                  </div>
                  <div v-else>
                    <div
                      v-for="file in docs[tipo.key]"
                      :key="file.id"
                      class="d-flex flex-column justify-content-start mb-50"
                    >
                      <div class="d-flex flex-row justify-content-start align-items-center mb-50">
                        <b-badge
                          variant="light-primary"
                          class="mb-0 p-50"
                          v-b-tooltip.hover
                          :title="file.nome"
                        >
                          <feather-icon icon="PaperclipIcon" size="18" />
                          {{ truncateWithEllipsis(file.nome) }}
                        </b-badge>
                        <feather-icon
                          class="ml-1 mb-0 text-primary cursor-pointer"
                          icon="DownloadIcon"
                          size="18"
                          @click="downloadFile(file)"
                        />
                        <feather-icon
                          class="ml-50 mb-0 text-primary cursor-pointer"
                          icon="Trash2Icon"
                          size="18"
                          @click="deleteFile(file)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b-button
                variant="outline-primary"
                class="bg-white w-100"
                @click="chooseFile(tipo.key)"
              >
                Incluir arquivo
              </b-button>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col class="d-flex flex-row justify-content-end" cols="12">
          <b-button :disabled="invalid" variant="primary" @click="updateTax"> Salvar </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BBadge,
  BButton,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, min_value, multiple1000 } from '@validations'
import vSelect from 'vue-select'
import { downloadURI, extractNameFromS3Url } from '@core/comp-functions/forms/cc-dropzone'
import { formatDate } from '@core/utils/filter'
import { truncateWithEllipsis } from '@core/comp-functions/data_visualization/string'

export default {
  name: 'TaxForm',
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BFormCheckbox,
    BBadge,
    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      selectedFileType: null,
      docs: [],
      documentTypes: [
        {
          title: 'Faturamento',
          key: 'faturamento',
        },
        {
          title: 'Três útimos balanços',
          key: 'balanco',
        },
        {
          title: 'Balancete',
          key: 'balancete',
        },
        {
          title: 'Relação de frota',
          key: 'lista_de_frota',
        },
      ],
      company: {
        id: null,
        taxa_juros: '',
        taxa_escrituracao: '',
        limite: 0,
        rating: '',
        analise_xlsx_link: '',
        analise_pdf_link: '',
        limite_aprovado: false,
        mora_atraso: '',
        multa_atraso: '',
        envio_documentacao_date: '',
      },
      deadlineOptions: [
        { value: 30, label: '30 dias' },
        { value: 45, label: '45 dias' },
        { value: 60, label: '60 dias' },
      ],
      required,
      min_value,
      multiple1000,
      money: {
        thousands: '.',
        prefix: 'R$ ',
        precision: 0,
      },
      percentage: {
        suffix: '%',
        prefix: '',
        precision: 4,
      },
      analysisResult: {},
    }
  },
  async mounted() {
    await this.getData()
  },
  methods: {
    async getData() {
      const { data } = await this.$store.dispatch('company/fetch', this.$route.params.id)

      this.docs = data.analise_credito_documentos.reduce((result, current) => {
        ;(result[current['tipo_documento']] = result[current['tipo_documento']] || []).push(current)
        return result
      }, {})

      this.company.id = data.id
      this.company.limite = data.limite ? parseFloat(data.limite).toFixed(0) : 0
      this.company.taxa_escrituracao = parseFloat(data.taxa_escrituracao) || 0
      this.company.taxa_juros = parseFloat(data.taxa_juros) || 0
      this.company.limite_aprovado = data.limite_aprovado
      this.company.envio_documentacao_date = data.envio_documentacao_date
      this.company.mora_atraso = parseFloat(data.mora_atraso) || 0
      this.company.multa_atraso = parseFloat(data.multa_atraso) || 0
      this.company.limite_por_dia = parseFloat(data.limite_por_dia) || 0
      this.companyAnalysisResult(data.id)
      await this.$refs.taxForm.reset()
    },
    chooseFile(type) {
      this.selectedFileType = type
      this.$refs.inputFile.click()
    },
    async handleFileUpload() {
      let selectedFile = this.$refs.inputFile.files[0]
      if (selectedFile) {
        const payload = {
          arquivo: selectedFile,
          tipo_documento: this.selectedFileType,
          empresa_id: this.company.id,
        }

        this.$swal.fire({
          title: 'Fazendo upload do arquivo',
          text: 'Aguarde um instante...',
          showConfirmButton: false,
          allowOutsideClick: false,
        })

        try {
          await this.$store.dispatch('company/sendDocumentToAnalysis', payload)
          this.$swal.close()
          await this.getData()
        } catch (error) {
          console.log(error)
        } finally {
          this.$refs.inputFile.value = null
          this.selectedFileType = null
        }
      }
    },
    truncateWithEllipsis,
    async downloadFile(file) {
      downloadURI(file.url)
    },
    async deleteFile(file) {
      this.$swal
        .fire({
          title: 'Deseja continuar?',
          text: `Você está prestes a deletar o arquivo ${file.nome}.`,
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Sim, deletar!',
        })
        .then(async (result) => {
          if (result.value) {
            this.$swal.showLoading()
            await this.$store.dispatch('company/deleteDocumentToAnalysis', file.id)
            this.$emit('deleted')
            this.$swal.fire('Pronto!', 'Arquivo deletado com sucesso.', 'success')
            await this.getData()
          }
        })
    },
    formatDate(date) {
      if (!date) return '---'

      return formatDate(date, true)
    },
    async updateTax() {
      try {
        this.$swal
          .fire({
            title: 'Deseja continuar?',
            text: 'Esta ação vai confirmar os valores de taxa e limite para a empresa.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Continuar!',
          })
          .then(async (result) => {
            if (result.value) {
              this.$swal.showLoading()
              console.log(this.company)
              await this.$store.dispatch('company/updateTax', this.company)
              this.$swal.fire('Atualizado!', 'Dados atualizados com sucesso.', 'success')
            }
          })
      } catch (error) {
        console.log(error)
        this.$swal.fire('Erro!', 'Ocorreu um erro ao atualizar taxas e limites.', 'error')
      }
    },
    async companyAnalysisResult(id) {
      const { data } = await this.$store.dispatch('company/getAnalysisResult', id)
      const resultado = data[0]?.resultado
      if (resultado) {
        this.analysisResult = resultado
        this.company.rating = parseFloat(resultado.rating).toFixed(2)
        this.company.analise_pdf_link = resultado.relatorio_pdf?.url
        this.company.analise_xlsx_link = resultado.relatorio_excel?.url
      }
    },
    downloadURI,
    extractNameFromS3Url,
  },
  computed: {
    analysisIsCompleted() {
      return this.analysisResult.status_analise === 'COMPLETED'
    },
  },
}
</script>

<style lang="scss">
.admin-docs {
  border: 1px solid #dae1e8;
  background: #f8fafc;
}
</style>
