<template>
  <tax-form />
</template>

<script>
import { BLink } from 'bootstrap-vue'
import TaxForm from '@/views/admin/companies/components/TaxForm.vue'

export default {
  name: 'GeneralData',
  components: {
    BLink,
    TaxForm,
  },
}
</script>

<style lang="scss">
@import '@core/scss/base/pages/app-file-manager';
</style>
