const truncateWithEllipsis = (str) => {
  if (str.length <= 10) return str

  const firstPart = str.substring(0, 5)
  const lastPart = str.substring(str.length - 8, str.length)

  return `${firstPart}...${lastPart}`
}

export { truncateWithEllipsis }
